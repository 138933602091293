<template>
    <card-list>
        <subheader-list-item icon="fa-align-left" title="Trip Information"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Title" label-position="inside">
                        <b-input v-model="trip.title"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Location" label-position="inside">
                        <b-input v-model="trip.location"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Start Date" label-position="inside">
                        <b-input type="date" v-model="trip.start_on"/>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="End Date" label-position="inside">
                        <b-input type="date" v-model="trip.end_on"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Timezone" label-position="inside">
                        <b-autocomplete
                            :data="tzResults"
                            field="title"
                            v-model="trip.trip_tz"
                            @select="selectTZ"
                            icon="search"
                            open-on-focus
                            placeholder="Search...">

                            <template slot="empty">No results.</template>
                        </b-autocomplete>
                    </b-field>
                </div>
            </div>
            <div class="columns mb-3">
                <div class="column">
                    <b-field label="Short Blurb" label-position="inside">
                        <b-input type="textarea" v-model="trip.blurb"/>
                    </b-field>
                </div>
            </div>
            <div class="columns mb-3">
                <div class="column">
                    <b-field label="Overview" label-position="inside">
                        <b-input type="textarea" v-model="trip.overview"/>
                    </b-field>
                </div>
            </div>
            <div class="columns mb-3">
                <div class="column">
                    <b-field label="Highlights" label-position="inside">
                        <b-input type="textarea" v-model="trip.highlights"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Base Trip Cost" label-position="inside">
                        <b-input v-model="trip.cost"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Deadline (x days)" label-position="inside">
                        <b-input v-model="trip.deadline"></b-input>
                    </b-field>
                </div>
            </div>
        </container-list-item>
        <subheader-list-item icon="fa-sliders-h" title="Settings"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-switch :true-value="1" :false-value="0" v-model="trip.active">Active</b-switch>
                </div>
                <div class="column">
                    <b-switch :true-value="1" :false-value="0" v-model="trip.waitlist">Wait List</b-switch>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-switch :true-value="1" :false-value="0" v-model="trip.featured">Featured</b-switch>
                </div>
                <div class="column">
                    <b-switch :true-value="1" :false-value="0" v-model="trip.closed">Registration Closed</b-switch>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-switch :true-value="1" :false-value="0" v-model="trip.participants_visible">Participants Visible (App)</b-switch>
                </div>
                <div class="column">
                    <b-switch :true-value="1" :false-value="0" v-model="trip.itinerary_visable">Itinerary Visible (App)</b-switch>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-switch :true-value="1" :false-value="0" v-model="trip.questions_enabled">Questions Enabled (App)</b-switch>
                </div>
                <div class="column">
                    <b-switch :true-value="1" :false-value="0" v-model="trip.archived">Archived</b-switch>
                </div>
            </div>
        </container-list-item>
        <subheader-list-item icon="fa-tag" title="Banner"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Text" label-position="inside">
                        <b-input v-model="trip.banner"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Size" label-position="inside">
                        <b-input v-model="trip.banner_size"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Color" label-position="inside">
                        <b-select expanded v-model="trip.banner_color">
                            <option value="red">Red</option>
                            <option value="yellow">Yellow</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
        </container-list-item>
    </card-list>
</template>

<script>
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    import moment from "moment-timezone";

    export default {
        name: 'TripForm',
        components: {ContainerListItem, SubheaderListItem, CardList},
        props: {
            trip: {
                type: Object,
                required: true
            }
        },
        data: function() {
            return {
                zones: moment.tz.names()
            };
        },
        computed: {
            tzResults() {
                if (this.trip && this.trip.trip_tz) {
                    return this.zones.filter((option) => {
                        return option
                            .toString()
                            .toLowerCase()
                            .indexOf(this.trip.trip_tz.toLowerCase()) >= 0;
                    });
                } else {
                    return null;
                }
            }
        },
        methods: {
            selectTZ(option) {
                // console.log('Selected', moment().tz(option).format('MMMM Do YYYY, h:mm:ss a Z'));
            }
        }
    };

</script>
